import React from 'react'


import CallToAction from '../components/callToAction.js';
import ArticleItem from '../components/articleItem.js';

import Footer from '../components/footer.js'

import HomeCTA from '../IMAGES/sfPhotoOne.jpg';

import articleOne from '../IMAGES/article-images/articleOne.jpg'
import articleTwo from '../IMAGES/article-images/articleTwo.jpg'
import articleThree from '../IMAGES/article-images/articleThree.jpg'
import articleFour from '../IMAGES/article-images/articleFour.jpg'
import articleFive from '../IMAGES/article-images/articleFive.jpg'

export default function homepage(props) {
    
    document.title = "CCHI Re | Home"

    return (
        <div id="homepage-container">
            <CallToAction ctaImage={HomeCTA} title="CCHI Re" subTitle="Providing brokers innovative risk management since 1986"/>
            <div id="about-section">
                <h1 id="about-heading">About Us</h1>
                <div id="about-text-container">
                    <p id="about-text">Brokers know how difficult it is to manage their client self-funded healthcare plans.  At CCHI Re, we have a provided broker Medical Stop Loss and Risk Management Services since 1986.</p>
                    <p id="about-text-heading">Open your window of opportunity. Call CCHI Re.</p>
                </div>
            </div>

            <div id="articles-container">

                <div className="home-testimonial">
                    <p>"Our retention is at 100% since we began this relationship 5 years ago. I only wish we had access to CCHI Re earlier as we grew our agency.”</p>
                    <h4>Kevin Godfrey, President, Godfrey Downs.</h4>
                </div>

                <ArticleItem
                    title={`CCHI Re Rx ${'\u2120'}`}
                    text="The fix your self-funded plan has been waiting for. Rx claims are drowning self-funded plans. This new approach is the life jacket your plan needs."
                    img={articleOne}
                    to="/home/article-one"
                />

                {/* <ArticleItem
                    title="Why you should self-fund your fully insured health plan"
                    text="There’s never been a more critical time to consider self-funding for fully insured groups over 50 lives."
                    img={articleTwo}
                    to="/home/article-two"
                /> */}

                <ArticleItem
                    title="Are you missing out on key medical stop loss markets?"
                    text="The experience that our stop loss team has with the carriers enables CCHI Re to negotiate exceptional rates for your clients. This service saves you time and enhances your bottom line."
                    img={articleThree}
                    to="/home/article-three"
                />

                <ArticleItem
                    title="Valuable strategies to lowering claims costs"
                    text={[<span className="bold">The I in CCHI stands for INNOVATION. </span>, `Since 1986 we have been designing strategies that cut claim costs and give us leverage in negotiating lower stop-loss rates.`]}
                    img={articleFour}
                    to="/home/article-four"
                />

                <div className="home-testimonial">
                    <p>"I’ve worked with Tom Rich for many years and have consistently been impressed with his experience, credibility, and trustworthiness in the stop-loss business. He doesn’t take short cuts and delivers comprehensive and creative solutions.”</p>

                    <h4>Scott Gusdorff, Senior Sales Consultant Anthem Life Insurance Company, Inc </h4>
                </div>

                <ArticleItem
                    title="Our Early Intervention program saves money, maximizes patient care quality and retains clients."
                    text=''
                    img={articleFive}
                    to="/home/article-five"
                />
            </div>

            <Footer />

        </div>
    )
}
